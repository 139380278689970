import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import backgroundImage from "../../assets/image/home-2/jpg/promo-bg-img.jpg";


const Content3 = ({ className, ...rest }) => {
  return (
    <>
      <div
        className={className}
        {...rest}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-8 col-md-10">
              <div
                className="text-center pt-14 pb-15 py-lg-31 dark-mode-texts"
                data-aos="zoom-in"
                data-aos-delay="500"
              >
                <StaticImage src="../../assets/image/logo-icon.png" alt="" className="mb-8" />
                <h2 className="font-size-11 mb-7">
                  Manage your forms the right way.
                </h2>
                <a
                  href="https://app.formpigeon.com/#/register"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn btn-dodger-blue-2 rounded-5 mt-12">
                    Try for free
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content3;
